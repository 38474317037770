import React from "react";
// import { Amplify, API, graphqlOperation } from 'aws-amplify'
// import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
// import '@aws-amplify/ui-react/styles.css';
// AMPLIFY
import { Amplify, I18n } from 'aws-amplify';
// import { Amplify } from 'aws-amplify';
import { Authenticator, useTheme, View, Heading, Button, useAuthenticator } from '@aws-amplify/ui-react';
// import { Authenticator, useTheme, View, Image, Text, Heading } from '@aws-amplify/ui-react';
// import { translations } from '@aws-amplify/ui';
import '@aws-amplify/ui-react/styles.css';

import awsExports from "../aws-exports";


// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components
import MonocerosNavbar from "components/navbars/MonocerosNavbar.js";
import MonocerosFooter from "components/footers/MonocerosFooter.js";
import TTS from "components/chat/TTS.js";

Amplify.configure(awsExports);


const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <></>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <></>
      
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.large}`}
          level={5}
          textAlign={'center'}
        >Introduce tu email y contraseña:</Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
            margin='1em'
          >
            He olvidado la contraseña
          </Button>
          
        </View>
      );
    },
  },

  // SignUp: {
  //   Header() {
  //     const { tokens } = useTheme();

  //     return (
  //       <Heading
  //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
  //         level={3}
  //       >
  //         Crear una nueva cuenta
  //       </Heading>
  //     );
  //   },
  //   Footer() {
  //     const { toSignIn } = useAuthenticator();

  //     return (
  //       <View textAlign="center">
  //         <Button
  //           fontWeight="normal"
  //           onClick={toSignIn}
  //           size="small"
  //           variation="link"
  //         >
  //           Volver a Log In
  //         </Button>
  //       </View>
  //     );
  //   },
  // },
};

function TTSUI(props) {
  React.useEffect(() => {
    document.body.classList.add("chat-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("chat-page");
    };
  });
  return (
    <Authenticator loginMechanisms={['email']} components={components}>
      {({ signOut, user }) => {
        
        if (user) {
          {/* setUser(user) */}
          {/* setSignOutFunction(signOut) */}
          {/* setUserEmail(normalizeEmail(user.attributes.email)) */}
          {/* console.log('Obtenemos user:', user) */}
          localStorage.setItem('user', JSON.stringify(user.attributes))
          {/* signin(user.attributes.email, signOut) */}
          const queryParams = new URLSearchParams(props.location.search);
          const code = queryParams.get("code");
          if (code) {
            localStorage.setItem('code', code)
          }
        }
        return (
          <>
            <MonocerosNavbar type="transparent" />
            <div className="wrapper">
              <div className="section-shaped my-0 skew-separator skew-mini">
                <div className="page-header page-header-small header-filter">
                  <div
                    className="page-header-image"
                    style={{
                      backgroundImage:
                        "url(https://images.unsplash.com/photo-1611532736579-6b16e2b50449?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2852&q=80)",
                    }}
                  ></div>
                </div>
              </div>
              <section>
                <Container className="pt-5 mb-5 upper">
                  <TTS />
                </Container>
              </section>
              <MonocerosFooter/>
            </div>
          </>
        )
        
      
      }}
    </Authenticator>
  );
}


export default TTSUI;
