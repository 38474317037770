import React from "react";
import axios from "axios";
import Slider from "nouislider";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroupItem,
  ListGroup,
  Media,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
} from "reactstrap";

// Core Components

function TTS() {
  const [searchFocus, setSearchFocus] = React.useState("");
  const [messageFocus, setMessageFocus] = React.useState("");
  const [textToSynth, setTextToSynth] = React.useState("");
  const [synthType, setSynthType] = React.useState("extra");
  const [audioQuality, setAudioQuality] = React.useState("mp3");
  const [audioLimit, setAudioLimit] = React.useState(240);
  const [breakTime, setBreakTime] = React.useState(500);
  const [speed, setSpeed] = React.useState(0.85);
  const [forceSynth, setForceSynth] = React.useState(true);
  // const [messages, setMessages] = React.useState([{text: "Hola", audio: "https://cadenaser.com/estaticos/recursosgraficos/responsive/especiales/victoria-la-voz-del-futbol/assets/media/step01.125081e9-107c-4bd4-addf-8e6d31f0d35e.mp3"}]);
  const [messages, setMessages] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    const items = JSON.parse(localStorage.getItem('messages'));
    if (items) {
      setMessages(items);
    }
    Slider.create(document.getElementById("speed"), {
      start: [0.85],
      connect: [true, false],
      step: 0.01,
      range: { min: 0.7, max: 1.2 },
    }).on('change', (data) => {
      if (data)
        setSpeed(data[0] || 1)
    })
  }, []);

  React.useEffect(() => {
    localStorage.setItem('messages', JSON.stringify(messages));
  }, [messages]);

  
  function imageExists(url, callback) {
    var img = new Image();
    img.onload = function() { callback(true, url); };
    img.onerror = function() { callback(false, url); };
    img.src = url;
  }

  function audioExists(url, callback) {
    const sound = new Audio(url);
    sound.addEventListener("error", function(e) {
        console.log("Playback error: ", e.currentTarget.error.code);
        callback(false, url)
    });
    sound.addEventListener('canplaythrough', function(e) { 
      console.log("canplaythrough event: ", e);
      callback(true, url);
    }, false);
    sound.load();
  }

  let retry = (function() {
    let count = 0;
  
    return function(url, max, timeout, next) {
      audioExists(url, function(exists, url){
        if (!exists) {
          console.log('fail');
    
          if (count++ < max) {
            return setTimeout(function() {
              retry(url, max, timeout, next);
            }, timeout);
          } else {
            return next(new Error('max retries reached'));
          }
        }
    
        console.log('success');
        next(null); 
      })
    }
  })();
  
  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  function addResponse(data) {
    if (data.audio) {

    }
    const audioMessage = {
      type: 'audio',
      audio: data.audio,
      date: new Date().toLocaleTimeString(),
      etd: data.etd
    };

    setMessages(old => [...old, audioMessage]);
  }

  async function addResponseAwait(requestedText, data) {
    setIsLoading(true)
    const audioMessage = {
      text: requestedText.message,
      type: 'audio',
      audio: data.audio,
      date: new Date().getTime(),
      etd: data.etd
    };
    await sleep(data.etd*1000)
    setIsLoading(false)
    setMessages(old => [...old, audioMessage]);
  }

  function sendTTS() {
    
    let newMessage = {
      message: textToSynth,
      type: 'text',
      date: new Date().toLocaleTimeString()
    }
    // const options = {
    //   method: 'POST',
    //   headers: {'X-API-key': 'rbVNtCGZLu6HnNrDFBDqn3oO79jZaAD6PnQ6krBj'},
    //   body: `{"text":"${textToSynth}","voice":"victoria","type":"conversational","version":"dev"}`
    // };
    
    const cleanText = textToSynth.replace(/(\r\n|\r|\n)/g, ' ')
    const textLength = cleanText.length

    const data = {
      "text": cleanText,
      "voice":"victoria",
      "type": synthType,
      "version":"dev",
      "audio-encoding": audioQuality,
      "break": breakTime,
      "audio_limit": audioLimit,
      "force": forceSynth,
      "duration": parseFloat(speed)
    }

    const options = {
      method: 'POST',
      // url: 'https://prisa-tts.monoceros.xyz/',
      url: ' https://y0jch16u91.execute-api.eu-west-1.amazonaws.com/dev/',
      headers: {'X-API-key': 'rbVNtCGZLu6HnNrDFBDqn3oO79jZaAD6PnQ6krBj'},
      data 
    };

    axios.request(options).then(function (response) {
      console.log('Response:', response.data);
      // const delayTime = response.data.etd*1000;
      // let startTime = new Date().getTime()
      // retry(response.data.audio, 1000, 100, function(err, body) {
      //     let finishTime = new Date().getTime()
      //     const totalTime = finishTime-startTime
      //     console.log('Tiempo:', totalTime, ' vs etd:', response.data.etd, ' - ctf:', textLength/totalTime, '- text length:', textLength)
      //     addResponse(response.data)
      // });
      addResponseAwait(newMessage, response.data)
    }).catch(function (error) {
      setIsLoading(false)
      console.error(error); 
    });

    // setMessages([...messages, newMessage])
  }

  return (
    <>
      <Row className="flex-row chat">
        {/* <Col lg="5">
          <Card className="bg-secondary">
            
              <CardBody>
              
              </CardBody>
              
            
          </Card>
          <Card className="">
          
          </Card>
        </Col> */}
        <Col lg="12">
          <Card>
            <CardHeader className="d-inline-block">
              <ListGroup className="list-group-chat" flush tag="div">
                <ListGroupItem
                  className="active bg-gradient-primary"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  tag="a"
                >
                  <Media>
                    <img
                      alt="..."
                      className="avatar"
                      // src={require("assets/img/faces/victoria_02.jpg")}
                      src={require("assets/img/brand/lavozdelfutbol_icon.svg")}
                    ></img>
                    <Media body className="ml-2">
                      <div className="justify-content-between align-items-center">
                        <h6 className="mb-0 text-white">
                          Victoria <Badge color="success"></Badge>
                        </h6>
                        <div>
                          <small>Online</small>
                        </div>
                      </div>
                    </Media>
                  </Media>
                </ListGroupItem>
              </ListGroup>
            </CardHeader>
            
            <CardFooter className="d-block">
              <FormGroup className={messageFocus}>
                  <Row className="mb-4">
                  <Col>
                      <label className="labels">
                        Texto a sintetizar{""}
                        <span className="text-danger">*</span>
                      </label>
                      <InputGroup className="mb-0">
                          
                          <Input
                            id="text-to-synth"
                            placeholder="Texto a sintetizar"
                            type="textarea"
                            width="100%"
                            onChange={(e) => setTextToSynth(`${e.target.value}`)}
                            onFocus={() => setMessageFocus("focused")}
                            onBlur={() => setMessageFocus("")}
                          ></Input>
                          <InputGroupAddon addonType="append">
                          <Button 
                            className=""
                              color="primary" 
                              id="synth-button"
                              type="submit"
                              disabled={textToSynth == "" || synthType == "" || isLoading} 
                              onClick={() => sendTTS()}>
                              <i></i>
                              {isLoading ? "Sintetizando..." : "Sintetizar"}
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                  </Col>

                  </Row>
                  
                    <Row className="mb-4">
                      <Col md="6">
                          <div className="js-form-message">
                            <label className="labels">
                              Tipo de síntesis{""}
                              <span className="text-danger">*</span>
                            </label>
                            <Input
                                data-trigger=""
                                id="synth-type"
                                name="synth-type"
                                type="select"
                                onChange={(e) => setSynthType(`${e.target.value}`)}
                              >
                                <option>Selecciona un tipo</option>
                                <option value="extra" selected="selected">Offline (No Alexa)</option>
                                <option value="long_text">Texto largo</option>
                                <option value="conversational">Síntesis rápida</option>
                              </Input>
                          </div>
                      </Col>
                      <Col md="6">
                          <div className="js-form-message">
                            <label className="labels">
                              Calidad del audio
                            </label>
                            <Input
                                data-trigger=""
                                id="synth-type"
                                name="synth-type"
                                type="select"
                                onChange={(e) => setAudioQuality(`${e.target.value}`)}
                              >
                                <option>Selecciona un tipo</option>
                                <option value="mp3_alexa">MP3 para Alexa</option>
                                <option value="mp3" selected="selected">MP3 170kbps</option>
                                {/* <option value="wav">WAV</option> */}
                              </Input>
                          </div>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col md="6">
                          <div className="js-form-message">
                            <label className="labels">
                              Silencio entre frases (ms)
                            </label>
                            <Input
                              aria-label="500"
                              name="break-time"
                              id="break-time"
                              placeholder="500"
                              min="100"
                              required=""
                              type="number"
                              defaultValue={breakTime}
                              onChange={(e) => setBreakTime(parseInt(`${e.target.value}`))}
                            ></Input>
                          </div>
                      </Col>

                      <Col md="6">
                          <div className="js-form-message">
                            <label className="labels">
                              Límite de audio (segundos)
                            </label>
                            <Input
                              aria-label="240"
                              name="audio-limit"
                              id="audio-limit"
                              placeholder="240"
                              min="1"
                              required=""
                              type="number"
                              defaultValue={audioLimit}
                              onChange={(e) => setAudioLimit(parseInt(`${e.target.value}`))}
                            ></Input>
                          </div>
                      </Col>
                    </Row>
                  
                    <Row>
                      <Col md="6">
                        <label className="labels">
                            Duración: {Math.round(speed*100)}% - {(speed == 1 ? 'Habla normal' : (speed < 1 ? 'Habla más rápido' : 'Habla más lento'))}
                        </label>
                        <div className="slider" id="speed"></div>
                      </Col>
                      <Col md="6">
                        <div className="js-form-message">
                          <label className="labels">
                            Reescribir audio? 
                          </label>
                          <div>
                            <label className="custom-toggle">
                              <Input defaultChecked type="checkbox" 
                                onChange={(e) => {
                                  
                                  console.log('Forzamos reescritura?', !forceSynth)
                                  setForceSynth(!forceSynth)
                                }}
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="No"
                                data-label-on="Sí"
                              />
                            </label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col></Col>
                    </Row>
              </FormGroup>
            </CardFooter>
          </Card>
          <Card>
          <CardBody>
          <Table className="table-pricing" responsive>
                      <thead className="text-primary">
                        <tr>
                          <th className="sent-message">
                            <h6 className="text-center">Texto solicitado</h6>
                          </th>
                          <th className="text-center">
                            <h6>Audio sintetizado</h6>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
            {
              [...messages].reverse().map( m => {
                {/* const audioMessage = {
                  text: requestedText.message,
                  type: 'audio',
                  audio: data.audio,
                  date: new Date().toLocaleTimeString(),
                  etd: data.etd
                }; */}

                return (
                  <tr key={m.date}>
                    <td>
                      <h6>{m.text}</h6>
                    </td>
                    <td className="text-center">
                    <audio
                        controls
                        src={m.audio}>
                            Your browser does not support the
                            <code>audio</code> element.
                    </audio>
                    </td>
                  </tr>
                )

                {/* if (m.type === 'text') {
                  return (
                    <Row className="justify-content-start">
                      <Col className="col-auto">
                        <Card>
                          <CardBody className="p-2">
                            <p className="mb-1">
                              {m.message}
                            </p>
                            <div>
                              <small className="opacity-60">
                                <i className="far fa-clock"></i>
                                {m.date}
                              </small>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  )
                } else if (m.type === 'audio') {
                  return (
                    <Row className="justify-content-end text-right">
                      <Col className="col-auto">
                        <Card className="bg-gradient-primary text-white">
                          <CardBody className="p-2">
                            <p className="mb-1">
                            <audio
                                controls
                                src={m.audio}>
                                    Your browser does not support the
                                    <code>audio</code> element.
                            </audio>
                            </p>
                            <div>
                              <small className="opacity-60">{m.date}</small>
                              <i className="ni ni-check-bold"></i>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  )
                } */}
              })
              
            }
            </tbody>
            </Table> 
              
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default TTS;
