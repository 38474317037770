import React from "react";
import ReactDOM from "react-dom";
import { Amplify } from 'aws-amplify';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/nucleo-svg.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-design-system.scss?v1.0.0";

import TTSPage from "views/TTSPage.js";


import awsExports from './aws-exports';
Amplify.configure(awsExports);


ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route
        path="/tts"
        exact
        render={(props) => <TTSPage {...props} />}
      />
      <Redirect to="/tts" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
