/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// Core Components

function MonocerosFooter() {
  return (
    <>
      <footer className="footer">
        <Container>
          <Row className="align-items-center justify-content-md-between">
            <Col md="6">
              <div className="copyright">
                © 2022{" "}
                <a
                  href="https://www.monoceroslabs.com"
                  target="_blank"
                >
                  Monoceros Labs S.L.
                </a>
              </div>
            </Col>
            <Col md="6">
              <Nav className="nav-footer justify-content-end">
                
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default MonocerosFooter;
